.MarigoldButton {
  margin-right: 2px;
}

.MarigoldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MarigoldField {
  width: 100%;
  margin-bottom: 4px;
}

.MarigoldFullWidth {
  width: 100%;
}

.MarigoldDrawerList {
  width: 250px;
}

.MarigoldDrawerFullList {
  width: auto;
}

.MarigoldFab {
  position: fixed;

  bottom: 16px;
  right: 16px;
}